import React, { useState, useRef, useCallback, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getCustomProductById } from "../../_services/unlockPhone";
import "../IPhoneFMICheck/iPhoneFMICheck.css";

const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";

const IPhoneFMICheck = () => {
  const { t } = useTranslation();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  const navigate = useNavigate();
  const [state, setState] = useState({
    productData: [],
    formValue: null,
    validToken: [],
  });
  const { startLoading, stopLoading } = useLoading();
  const captchaRef = useRef(null);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const res = await getCustomProductById(5);
        setState((prevState) => ({ ...prevState, productData: res }));
      } catch (error) {
        toast.error("Error fetching product data.");
      } finally {
      }
    };
    fetchProductData();
  }, []);

  const validationSchema = Yup.object({
    imei: Yup.string()
      .required(t("ErrorField4"))
      .length(15, t("ErrorField41")),
    email: Yup.string().email(t("ErrorField51")).required(t("ErrorField5")),
  });
  // State to force re-rendering of the ReCAPTCHA component
  const [captchaKey, setCaptchaKey] = useState(Date.now()); // Use timestamp as a key for re-rendering
  // Reload captcha when language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguageCode = cookies.get("i18next") || "en";
      if (newLanguageCode !== currentLanguageCode) {
        setCurrentLanguageCode(newLanguageCode);
        // Change the key to remount ReCAPTCHA
        setCaptchaKey(Date.now());
      }
    };
    // Listen for cookie changes or language changes (you could also use another event)
    const intervalId = setInterval(handleLanguageChange, 1000); // Polling for language changes (or use other detection)
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentLanguageCode]);
  const verifyToken = async (token) => {
    try {
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });
      toast.success(t("IEMIS1"));
      return response.data;
    } catch (error) {
      toast.error("Verification failed. Please try again.");
      return null;
    }
  };

  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      try {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        if (!token) {
          return toast.error(t("Sorry!! Verify you're not a bot"));
        }

        startLoading();
        const validTokenResponse = await verifyToken(token);
        if (!validTokenResponse?.success) {
          throw new Error("Verification failed");
        }

        const updatedValues = {
          ...values,
          carrier: "Global",
          manufacturer: "iCloud lock checker and FMI (ON/OF)",
          model: "iPhone FMI Check (Clean/Lost)",
          phone: "",
        };

        setState((prevState) => ({
          ...prevState,
          formValue: updatedValues,
          validToken: validTokenResponse,
        }));

        navigate("/review-order", { state: { formValue: updatedValues, productData: state.productData } });
        localStorage.setItem("state", JSON.stringify({ formValue: updatedValues, productData: state.productData }));
      } catch (error) {
        toast.error("Something went wrong.");
      } finally {
        stopLoading();
      }
    },
    [navigate, state.productData, t, startLoading, stopLoading]
  );
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-md-5 pt-0 pb-0 position-relative">
        <div className="container py-2 pt-md-4">
          <div className="row justify-content-center p-3">
            <div className="col-md-6 col-12 px-md-5 p-0">
              <h1 className="fw-semibold display-6 mt-md-5">
              {t('Ifmickh1')}
              </h1>
              <p className=" imeiblacklist balance text-justify m-0 pt-4">
              {t('Ifmickp1')}
              </p>
            </div>
            <div className="col-12 col-md-6 mt-md-0 mt-3 p-0">
              <>
                <Formik
                  initialValues={{ imei: "", email: "" }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ handleChange }) => (
                    <Form className="bg-white rounded p-4 form-border">
                      <div className="card unlock-card bg-transparent border-0 my-md-0 my-1">
                        <div className="card-body">
                          <h4 className="fw-semibold mb-4">{t('Ifmickf1')}</h4>
                          <div>
                            <p className="mb-0 fw-semibold">{t('Icunp6')}</p>
                            <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                              <label className="input-group-text bg-transparent border-0" htmlFor="imei">
                                <img loading="lazy" src="/images/ph_phone-call (1).svg" alt="" />
                                <span className="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="text"
                                className="form-control border-0 py-1"
                                placeholder={t("FormField4")}
                                aria-label={t("FormField4")}
                                id="imei"
                                name="imei"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="imei" component="div" className="error-message ps-5 ms-4" />
                          <div>
                            <p className="mb-0 fw-semibold">Email</p>
                            <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                              <label className="input-group-text bg-transparent border-0" htmlFor="email">
                                <img loading="lazy" src="/images/at-sign2.svg" alt="" />
                                <span className="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="email"
                                className="form-control border-0 py-1"
                                placeholder={t("FormField5")}
                                aria-label={t("FormField5")}
                                id="email"
                                name="email"
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <ErrorMessage name="email" component="div" className="error-message ps-5 ms-4" />
                          <button type="submit" className="btn btn-lg mt-md-0 mt-2 w-100 unlock-btn">
                            {t("UsefullB1")}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
                <div>
                  <ReCAPTCHA
                    key={captchaKey}
                    className="recaptcha"
                    sitekey={SITE_KEY}
                    ref={captchaRef}
                    hl={currentLanguageCode}
                  />
                </div>
              </>
              <div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IPhoneFMICheck;
